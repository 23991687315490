import React from 'react';

const EmailPreview = ({ content }) => {
    return (
        <div className="email-preview-container">
            <h3>Aperçu de l'email</h3>
            <div className="email-content" dangerouslySetInnerHTML={{ __html: content }} />
        </div>
    );
};

export default EmailPreview;
