import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CampaignList from '../../../components/organisms/CampaignList/CampaignList';
import Button from '../../../components/atoms/Button/Button';
import config from "../../../config";
import Breadcrumb from "../../../components/molecules/Breadcrumb/Breadcrumb";
import { useAuthFetch } from "../../../hooks/useAuthFetch";


const Campaigns = () => {
    const [campaigns, setCampaigns] = useState([]);
    const navigate = useNavigate();
    const authFetch = useAuthFetch();


    useEffect(() => {
        const fetchCampaigns = async () => {
            try {
                const response = await authFetch(`${config.API_URL}/emailing/campaign/list`);
                if (!response.ok) {
                    throw new Error(`Erreur HTTP: ${response.status}`);
                }
                const data = await response.json();
                setCampaigns(Array.isArray(data) ? data : []);
            } catch (error) {
                console.error("Erreur lors de la récupération des campagnes:", error);
            }
        };

        fetchCampaigns();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleAddCampaignClick = () => {
        navigate('/campagnes/ajouter');
    };


    return (
        <div>
            <Breadcrumb />
            <h1>Campagnes</h1>
            <Button className="button-sticky-top" onClick={handleAddCampaignClick}>Ajouter une Campagne</Button>
            <CampaignList campaigns={campaigns} />
        </div>
    );
};

export default Campaigns;
