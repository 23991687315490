import React, { useState, useEffect } from 'react';
import './DashboardEmailing.css';
import config from "../../../config";
import { useAuthFetch } from "../../../hooks/useAuthFetch";
import { convertToDDMMYYYY } from "../../../utils/dateUtils";
import Button from "../../../components/atoms/Button/Button";
import * as XLSX from 'xlsx';

const DashboardEmailing = () => {
    const [data, setData] = useState(null);
    const [showUnsubscribers, setShowUnsubscribers] = useState(false);
    const [expandedCampaigns, setExpandedCampaigns] = useState({});
    const authFetch = useAuthFetch();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await authFetch(`${config.API_URL}/emailing/stats`);
                const result = await response.json();
                setData(result);
            } catch (error) {
                console.error('Error fetching data', error);
            }
        };

        fetchData();
    }, [authFetch]);

    if (!data) {
        return <div>Loading...</div>;
    }

    const {
        liste_desabonnes = [],
        nombre_desabonnes = 0,
        stats_par_campagne = {},
        stats_globales = {}
    } = data;

    const critereLabels = {
        formula: "Formules",
        lang: "Langues",
        country: "Pays"
    };

    const renderCriteres = (campaign, critere) => {
        const envois = campaign.envois_par_criteres?.[critere] || {};
        const ouvertures = campaign.ouvertures_par_criteres?.[critere] || {};
        const ouvertures_uniques = campaign.ouvertures_uniques_par_criteres?.[critere] || {};
        const ouvertures_rate = campaign.ouvertures_rate_par_criteres?.[critere] || {};
        const ouvertures_uniques_rate = campaign.ouvertures_uniques_rate_par_criteres?.[critere] || {};
        const clics = campaign.clics_par_criteres?.[critere] || {};
        const clics_uniques = campaign.clics_uniques_par_criteres?.[critere] || {};
        const clics_rate = campaign.clics_rate_par_criteres?.[critere] || {};
        const clics_uniques_rate = campaign.clics_uniques_rate_par_criteres?.[critere] || {};

        return (
            <div key={critere}>
                <strong>{critereLabels[critere]}:</strong>
                <div className="critere-table">
                    <div className="critere-row critere-header">
                        <div>{critereLabels[critere]}</div>
                        <div>Envois</div>
                        {campaign.ouvertures_par_criteres && <div>Ouvertures</div>}
                        {campaign.ouvertures_uniques_par_criteres && <div>Ouvertures Uniques</div>}
                        {campaign.ouvertures_rate_par_criteres && <div>Taux d'Ouverture</div>}
                        {campaign.ouvertures_uniques_rate_par_criteres && <div>Taux d'Ouvertures Uniques</div>}
                        {campaign.clics_par_criteres && <div>Clics</div>}
                        {campaign.clics_uniques_par_criteres && <div>Clics Uniques</div>}
                        {campaign.clics_rate_par_criteres && <div>Taux de Clics</div>}
                        {campaign.clics_uniques_rate_par_criteres && <div>Taux de Clics Uniques</div>}
                    </div>
                    {Object.keys(envois).map(key => (
                        <div key={key} className="critere-row">
                            <div>{key}</div>
                            <div>{envois[key]}</div>
                            {campaign.ouvertures_par_criteres && <div>{ouvertures[key] || '-'}</div>}
                            {campaign.ouvertures_uniques_par_criteres && <div>{ouvertures_uniques[key] || '-'}</div>}
                            {campaign.ouvertures_rate_par_criteres && <div>{ouvertures_rate[key]?.ouvertures_rate_percent || '-'}</div>}
                            {campaign.ouvertures_uniques_rate_par_criteres && <div>{ouvertures_uniques_rate[key]?.ouvertures_uniques_rate_percent || '-'}</div>}
                            {campaign.clics_par_criteres && <div>{clics[key] || '-'}</div>}
                            {campaign.clics_uniques_par_criteres && <div>{clics_uniques[key] || '-'}</div>}
                            {campaign.clics_rate_par_criteres && <div>{clics_rate[key]?.clics_rate_percent || '-'}</div>}
                            {campaign.clics_uniques_rate_par_criteres && <div>{clics_uniques_rate[key]?.clics_uniques_rate_percent || '-'}</div>}
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const renderGlobalCriteres = (critere) => {
        const envois = stats_globales.envois_par_criteres?.[critere] || {};
        const ouvertures = stats_globales.ouvertures_par_criteres?.[critere] || {};
        const ouvertures_uniques = stats_globales.ouvertures_uniques_par_criteres?.[critere] || {};
        const ouvertures_rate = stats_globales.ouvertures_rate_par_criteres?.[critere] || {};
        const ouvertures_uniques_rate = stats_globales.ouvertures_uniques_rate_par_criteres?.[critere] || {};
        const clics = stats_globales.clics_par_criteres?.[critere] || {};
        const clics_uniques = stats_globales.clics_uniques_par_criteres?.[critere] || {};
        const clics_rate = stats_globales.clics_rate_par_criteres?.[critere] || {};
        const clics_uniques_rate = stats_globales.clics_uniques_rate_par_criteres?.[critere] || {};

        return (
            <div key={critere}>
                <strong>{critereLabels[critere]}:</strong>
                <div className="critere-table">
                    <div className="critere-row critere-header">
                        <div>{critereLabels[critere]}</div>
                        <div>Envois</div>
                        {stats_globales.ouvertures_par_criteres && <div>Ouvertures</div>}
                        {stats_globales.ouvertures_uniques_par_criteres && <div>Ouvertures Uniques</div>}
                        {stats_globales.ouvertures_rate_par_criteres && <div>Taux d'Ouverture</div>}
                        {stats_globales.ouvertures_uniques_rate_par_criteres && <div>Taux d'Ouvertures Uniques</div>}
                        {stats_globales.clics_par_criteres && <div>Clics</div>}
                        {stats_globales.clics_uniques_par_criteres && <div>Clics Uniques</div>}
                        {stats_globales.clics_rate_par_criteres && <div>Taux de Clics</div>}
                        {stats_globales.clics_uniques_rate_par_criteres && <div>Taux de Clics Uniques</div>}
                    </div>
                    {Object.keys(envois).map(key => (
                        <div key={key} className="critere-row">
                            <div>{key}</div>
                            <div>{envois[key]}</div>
                            {stats_globales.ouvertures_par_criteres && <div>{ouvertures[key] || '-'}</div>}
                            {stats_globales.ouvertures_uniques_par_criteres && <div>{ouvertures_uniques[key] || '-'}</div>}
                            {stats_globales.ouvertures_rate_par_criteres && <div>{ouvertures_rate[key]?.ouvertures_rate_percent || '-'}</div>}
                            {stats_globales.ouvertures_uniques_rate_par_criteres && <div>{ouvertures_uniques_rate[key]?.ouvertures_uniques_rate_percent || '-'}</div>}
                            {stats_globales.clics_par_criteres && <div>{clics[key] || '-'}</div>}
                            {stats_globales.clics_uniques_par_criteres && <div>{clics_uniques[key] || '-'}</div>}
                            {stats_globales.clics_rate_par_criteres && <div>{clics_rate[key]?.clics_rate_percent || '-'}</div>}
                            {stats_globales.clics_uniques_rate_par_criteres && <div>{clics_uniques_rate[key]?.clics_uniques_rate_percent || '-'}</div>}
                        </div>
                    ))}
                </div>
            </div>
        );
    };


    const toggleCampaignExpansion = (campaignId) => {
        setExpandedCampaigns(prevState => ({
            ...prevState,
            [campaignId]: !prevState[campaignId]
        }));
    };

    const downloadExcel = () => {
        const workbook = XLSX.utils.book_new();

        // Global stats
        const globalData = [
            ["Metric", "Value"],
            ["Envois", stats_globales.envois || '-'],
            ["Ouvertures", stats_globales.ouvertures || '-'],
            ["Ouvertures Uniques", stats_globales.ouvertures_uniques || '-'],
            ["Taux d'Ouverture", stats_globales.ouvertures_rate_percent || '-'],
            ["Taux d'Ouvertures Uniques", stats_globales.ouvertures_uniques_rate_percent || '-'],
            ["Clics", stats_globales.clics || '-'],
            ["Clics Uniques", stats_globales.clics_uniques || '-'],
            ["Taux de Clics", stats_globales.clic_rate_percent || '-'],
            ["Taux de Clics Uniques", stats_globales.clic_rate_unique_percent || '-']
        ];

        const globalSheet = XLSX.utils.aoa_to_sheet(globalData);
        XLSX.utils.book_append_sheet(workbook, globalSheet, "Global Stats");

        // Global criteria stats
        const critereLabels = {
            formula: "Formules",
            lang: "Langues",
            country: "Pays"
        };

        Object.keys(critereLabels).forEach(critere => {
            const critereData = [
                [critereLabels[critere], "Envois", "Ouvertures", "Ouvertures Uniques", "Taux d'Ouverture", "Taux d'Ouvertures Uniques", "Clics", "Clics Uniques" , "Taux de Clics", "Taux de Clics Uniques"],
                ...Object.keys(stats_globales.envois_par_criteres?.[critere] || {}).map(key => [
                    key,
                    stats_globales.envois_par_criteres[critere][key] || '-',
                    stats_globales.ouvertures_par_criteres?.[critere]?.[key] || '-',
                    stats_globales.ouvertures_uniques_par_criteres?.[critere]?.[key] || '-',
                    stats_globales.ouvertures_rate_par_criteres?.[critere]?.[key]?.ouvertures_rate_percent || '-',
                    stats_globales.ouvertures_uniques_rate_par_criteres?.[critere]?.[key]?.ouvertures_uniques_rate_percent || '-',
                    stats_globales.clics_par_criteres?.[critere]?.[key] || '-',
                    stats_globales.clics_uniques_par_criteres?.[critere]?.[key] || '-',
                    stats_globales.clics_rate_par_criteres?.[critere]?.[key]?.clics_rate_percent || '-',
                    stats_globales.clics_uniques_rate_par_criteres?.[critere]?.[key]?.clics_uniques_rate_percent || '-'
                ])
            ];
            const critereSheet = XLSX.utils.aoa_to_sheet(critereData);
            XLSX.utils.book_append_sheet(workbook, critereSheet, `Global - ${critereLabels[critere]}`);
        });

        // Campaigns stats
        Object.keys(stats_par_campagne).forEach(campaignId => {
            const campaign = stats_par_campagne[campaignId];
            const campaignData = [
                ["Metric", "Value"],
                ["Envois", campaign.envois || '-'],
                ["Ouvertures", campaign.ouvertures || '-'],
                ["Ouvertures Uniques", campaign.ouvertures_uniques || '-'],
                ["Taux d'Ouverture", campaign.ouvertures_rate_percent || '-'],
                ["Taux d'Ouvertures Uniques", campaign.ouvertures_uniques_rate_percent || '-'],
                ["Clics", campaign.clics || '-'],
                ["Clics Uniques", campaign.clics_uniques || '-'],
                ["Taux de Clics", campaign.clic_rate_percent || '-'],
                ["Taux de Clics Uniques", campaign.clic_rate_unique_percent || '-']
            ];

            const campaignSheet = XLSX.utils.aoa_to_sheet(campaignData);
            XLSX.utils.book_append_sheet(workbook, campaignSheet, campaign.campaign_name.slice(0, 31));

            // Campaign criteria stats
            Object.keys(critereLabels).forEach(critere => {
                const critereData = [
                    [critereLabels[critere], "Envois", "Ouvertures", "Ouvertures Uniques", "Taux d'Ouverture" , "Taux d'Ouvertures Uniques" , "Clics", "Clics Uniques", "Taux de Clics", "Taux de Clics Uniques"],
                    ...Object.keys(campaign.envois_par_criteres?.[critere] || {}).map(key => [
                        key,
                        campaign.envois_par_criteres[critere][key] || '-',
                        campaign.ouvertures_par_criteres?.[critere]?.[key] || '-',
                        campaign.ouvertures_uniques_par_criteres?.[critere]?.[key] || '-',
                        campaign.ouvertures_rate_par_criteres?.[critere]?.[key]?.ouvertures_rate_percent || '-',
                        campaign.ouvertures_uniques_rate_par_criteres?.[critere]?.[key]?.ouvertures_uniques_rate_percent || '-',
                        campaign.clics_par_criteres?.[critere]?.[key] || '-',
                        campaign.clics_uniques_par_criteres?.[critere]?.[key] || '-',
                        campaign.clics_rate_par_criteres?.[critere]?.[key]?.clics_rate_percent || '-',
                        campaign.clics_uniques_rate_par_criteres?.[critere]?.[key]?.clics_uniques_rate_percent || '-'
                    ])
                ];
                const critereSheet = XLSX.utils.aoa_to_sheet(critereData);
                const sheetName = `${campaign.campaign_name.slice(0, 31 - critereLabels[critere].length - 3)} - ${critereLabels[critere]}`;
                XLSX.utils.book_append_sheet(workbook, critereSheet, sheetName);
            });
        });

        XLSX.writeFile(workbook, "emailing_stats.xlsx");
    };


    return (
        <div className="dashboard-emailing">
            <h1>Emailing Dashboard</h1>
            <Button onClick={downloadExcel}>Download Excel</Button>
            <section>
                <h2>Global Stats</h2>
                <div className="stats-globales">
                    <p><strong>Envois:</strong> {stats_globales.envois || '-'}</p>
                    <p><strong>Ouvertures:</strong> {stats_globales.ouvertures || '-'}</p>
                    <p><strong>Ouvertures Uniques:</strong> {stats_globales.ouvertures_uniques || '-'}</p>
                    <p><strong>Taux d'Ouverture:</strong> {stats_globales.ouvertures_rate_percent || '-'}</p>
                    <p><strong>Taux d'Ouvertures Uniques:</strong> {stats_globales.ouvertures_uniques_rate_percent || '-'}</p>
                    <p><strong>Clics:</strong> {stats_globales.clics || '-'}</p>
                    <p><strong>Clics Uniques:</strong> {stats_globales.clics_uniques || '-'}</p>
                    <p><strong>Taux de Clics:</strong> {stats_globales.clic_rate_percent || '-'}</p>
                    <p><strong>Taux de Clics Uniques:</strong> {stats_globales.clic_rate_unique_percent || '-'}</p>
                </div>
                <div className="criteres">
                    <h4>Données par Critères</h4>
                    {['formula', 'lang', 'country'].map(critere => renderGlobalCriteres(critere))}
                </div>
            </section>


            <section>
                <h2>Stats par Campagne</h2>
                {Object.keys(stats_par_campagne).map((campaignId) => {
                    const campaign = stats_par_campagne[campaignId];
                    return (
                        <div key={campaignId} className="campaign">
                            <h3 onClick={() => toggleCampaignExpansion(campaignId)}>
                                {campaign.campaign_name} - {convertToDDMMYYYY(campaign.campaign_date)}
                                {expandedCampaigns[campaignId] ? ' ▼' : ' ►'}
                            </h3>
                            {expandedCampaigns[campaignId] && (
                                <>
                                    <p><strong>Envois:</strong> {campaign.envois || '-'}</p>
                                    <p><strong>Ouvertures:</strong> {campaign.ouvertures || '-'}</p>
                                    <p><strong>Ouvertures Uniques:</strong> {campaign.ouvertures_uniques || '-'}</p>
                                    <p><strong>Taux d'Ouverture:</strong> {campaign.ouvertures_rate_percent || '-'}</p>
                                    <p><strong>Taux d'Ouvertures Uniques:</strong> {campaign.ouvertures_uniques_rate_percent || '-'}</p>
                                    <p><strong>Clics:</strong> {campaign.clics || '-'}</p>
                                    <p><strong>Clics Uniques:</strong> {campaign.clics_uniques || '-'}</p>
                                    <p><strong>Taux de Clics:</strong> {campaign.clic_rate_percent || '-'}</p>
                                    <p><strong>Taux de Clics Uniques:</strong> {campaign.clic_rate_unique_percent || '-'}</p>
                                    <div className="criteres">
                                        <h4>Données par Critères</h4>
                                        {['formula', 'lang', 'country'].map(critere => renderCriteres(campaign, critere))}
                                    </div>
                                </>
                            )}
                        </div>
                    );
                })}
            </section>

            <section>
                <h2>Désabonnés</h2>
                <p><strong>Nombre de désabonnés:</strong> {nombre_desabonnes}</p>
                <Button onClick={() => setShowUnsubscribers(!showUnsubscribers)}>
                    {showUnsubscribers ? 'Masquer' : 'Afficher'} la liste des désabonnés
                </Button>
                {showUnsubscribers && (
                    <ul>
                        {liste_desabonnes.map((email, index) => (
                            <li key={index}>{email}</li>
                        ))}
                    </ul>
                )}
            </section>
        </div>
    );
};

export default DashboardEmailing;
