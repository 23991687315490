import React from 'react';
import TextInput from "../../atoms/TextInput/TextInput";
import Button from "../../atoms/Button/Button";
import './CodeLangField.css';

const CodeLangFields = ({ index, codeLang, onCodeLangChange, onDelete }) => (
    <div className="codeLang-field">
        <div className="form-group">
            <TextInput
                value={codeLang}
                onChange={e => onCodeLangChange(e.target.value)}
                placeholder="Code Lang"
            />
            <Button onClick={() => onDelete(index)}>Supprimer</Button>
        </div>
    </div>
);

export default CodeLangFields;
