import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.css';
import { AuthContext } from '../../../hooks/AuthContext';

const Sidebar = () => {
    const { role } = useContext(AuthContext);

    return (
        <div className="sidebar">
            <div className="menu-container">
                {role === 'admin' && (
                    <>
                        <Link to="/">Dashboard</Link>
                        <Link to="/campagnes">Campagnes Emailing</Link>
                        <Link to="/prices">Prix & Taux</Link>
                        <Link to="/countries">Formules & Pays</Link>
                        <Link to="/credentials">Logins Banques</Link>
                        <Link to="/logs">Logs</Link>
                    </>
                )}
                {role === 'collaborateur' && (
                    <Link to="/">Dashboard</Link>
                )}
            </div>
        </div>
    );
};

export default Sidebar;
