const config = {
    "OPERATION_NAME":"MASTER PLATFORM",
    "API_URL":"https://api.master.b-fly.com",
    "TINY_MCE_API_KEY":"1527x3ef5ajbywfdm25kadjmxzahw16uvrzdkfdn0sydl15y",
    "AUTH0_DOMAIN": "butterfly-henner.eu.auth0.com",
    "AUTH0_CLIENT_ID": "XBP54YwRFg1mqqRKBjvUHpiD79v6Nc18",
    "AUTH0_AUDIENCE": "https://api.master.b-fly.com/",
};

export default config;
