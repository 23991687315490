import React, { useEffect } from 'react';
import Button from "../../atoms/Button/Button";
import TextInput from "../../atoms/TextInput/TextInput";
import Select from "../../atoms/Select/Select";

const RatesFields = ({ index, field, onFieldChange, onDelete, codeOptions }) => {
    const options = Object.entries(codeOptions || {}).map(([code, currency]) => {
        return { label: `${code} - ${currency}`, value: code };
    });

    useEffect(() => {
        if (!field.currency && options.length > 0) {
            onFieldChange(index, 'currency', options[0].value);
        }
    }, [field.currency, index, onFieldChange, options]);

    return (
        <div className="rates-field">
            <div className="form-group">
                <label htmlFor={`currency-${index}`}>Devise</label>
                <Select
                    id={`currency-${index}`}
                    value={field.currency}
                    onChange={e => onFieldChange(index, 'currency', e.target.value)}
                    options={options}
                />

                <label htmlFor={`rate-${index}`}>Taux de change</label>
                <TextInput
                    id={`rate-${index}`}
                    className="prices-input"
                    value={field.rate}
                    onChange={e => onFieldChange(index, 'rate', e.target.value)}
                    placeholder="taux"
                />

                <label htmlFor={`local_price-${index}`}>Prix local</label>
                <TextInput
                    id={`local_price-${index}`}
                    className="prices-input"
                    value={field.local_price}
                    onChange={e => onFieldChange(index, 'local_price', e.target.value)}
                    placeholder="prix local"
                />

                <Button onClick={() => onDelete(index)}>Supprimer</Button>
            </div>
        </div>
    );
};

export default RatesFields;
