import React, { useState } from 'react';
import './HelpPopUp.css';

const HelpPopUp = ({ instructions }) => {
    const [isOpen, setIsOpen] = useState(false);

    const togglePopup = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div>
            {isOpen ? (
                <div className="help-popup">
                    <div className="help-popup-content">
                        <button onClick={togglePopup} className="close-button">&times;</button>
                        <ul>
                            {instructions.map((instruction, index) => (
                                <li key={index}>{instruction}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            ) : (
                <button onClick={togglePopup} className="help-button">?</button>
            )}
        </div>
    );
};

export default HelpPopUp;