import React from 'react';
import './FilterBar.css';

const FilterBar = ({ onEmailChange, onIsSentChange, emailValue, isSentValue }) => {
    return (
        <div className="filter-bar">
            <input
                type="text"
                placeholder="Filtrer par email"
                value={emailValue}
                onChange={onEmailChange}
            />
            <select value={isSentValue} onChange={onIsSentChange}>
                <option value="">Tous</option>
                <option value="true">Envoyé</option>
                <option value="false">Non envoyé</option>
            </select>
        </div>
    );
};

export default FilterBar;
