// CodeLangSingleForm.js
import React, { useState, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import config from '../../../config';
import './CodeLangSingleForm.css';

const CodeLangSingleForm = ({ codeLangSingle, placeholders, onContentChange, onDuplicate }) => {
    const [localPlaceholders, setLocalPlaceholders] = useState(placeholders);

    useEffect(() => {
        setLocalPlaceholders(placeholders);
    }, [placeholders]);

    const handleEditorChange = (content, placeholderUuid) => {
        const updatedPlaceholders = localPlaceholders.map(placeholder =>
            placeholder.uuid === placeholderUuid ? { ...placeholder, value: content } : placeholder
        );
        setLocalPlaceholders(updatedPlaceholders);

        if (onContentChange) {
            onContentChange(codeLangSingle, updatedPlaceholders);
        }
    };

    return (
        <div className="code-lang-single-form">
            <div className="code-lang-header">
                <h2 className="code-lang-title">{codeLangSingle}</h2>
                <button className="duplicate-button button" onClick={() => onDuplicate(codeLangSingle)}>
                    Dupliquer les traductions de {codeLangSingle}
                </button>
            </div>
            <div className="placeholders-container">
                {localPlaceholders.map(placeholder => (
                    <div className="placeholder-row" key={placeholder.uuid}>
                        <label className="placeholder-label" title={placeholder.name}>{placeholder.name}</label>
                        <Editor
                            apiKey={config.TINY_MCE_API_KEY}
                            value={placeholder.value}
                            init={{
                                newline_behavior: 'linebreak',
                                force_br_newlines: true,
                                force_p_newlines: false,
                                paste_as_text: true,
                                height: '300px',
                                width: '100%',
                                plugins: 'code directionality anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount nonbreaking',
                                toolbar: 'undo redo code | nonbreaking fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | ltr rtl | removeformat',
                                invalid_elements: 'p',
                            }}
                            onEditorChange={(content) => handleEditorChange(content, placeholder.uuid)}
                            className="placeholder-editor"
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CodeLangSingleForm;
