import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/templates/Layout/Layout.js';
import './global.css';
import Prices from "./pages/Prices/Prices";
import Profile from "./pages/Profile/Profile";
import DashboardEmailing from "./pages/Emailing/Dashboard/DashboardEmailing";
import Countries from "./pages/Emailing/Countries/Countries";
import Credentials from "./pages/Emailing/Credentials/Credentials";
import Campaigns from "./pages/Emailing/Campaigns/Campaigns";
import AddCampaign from "./pages/Emailing/AddCampaign/AddCampaign";
import EditCampaign from "./pages/Emailing/EditCampaign/EditCampaign";
import Campaign from "./pages/Emailing/Campaign/Campaign";
import AddBatch from "./pages/Emailing/AddBatch/AddBatch";
import EditBatch from "./pages/Emailing/EditBatch/EditBatch";
import Batch from "./pages/Emailing/Batch/Batch";
import AddTemplate from "./pages/Emailing/AddTemplate/AddTemplate";
import AddCodeLang from "./pages/Emailing/AddCodeLang/AddCodeLang";
import AddEligibles from "./pages/Emailing/AddEligibles/AddEligibles";
import EditTemplate from "./pages/Emailing/EditTemplate/EditTemplate";
import EditCodeLang from "./pages/Emailing/EditCodeLang/EditCodeLang";
import Logs from "./pages/Emailing/Logs/Logs";
import NoAccess from "./pages/Emailing/NoAccess/NoAccess";
import { NotificationProvider } from "./NotificationContext";
import { AuthProvider } from './hooks/AuthContext';
import ProtectedRoute from './components/templates/Auth/ProtectedRoute';

const App = () => {
    return (
        <NotificationProvider>
            <AuthProvider>
                <Router>
                    <Routes>
                        {/* Dashboard route */}
                        <Route path="/" element={
                            <ProtectedRoute requiredRoles={['collaborateur']}>
                                <Layout>
                                    <DashboardEmailing />
                                </Layout>
                            </ProtectedRoute>
                        } />

                        {/* Profile route */}
                        <Route path="/profile" element={
                            <ProtectedRoute requiredRoles={['admin']}>
                                <Layout>
                                    <Profile />
                                </Layout>
                            </ProtectedRoute>
                        } />

                        {/* Credentials route */}
                        <Route path="/credentials" element={
                            <ProtectedRoute requiredRoles={['admin']}>
                                <Layout>
                                    <Credentials />
                                </Layout>
                            </ProtectedRoute>
                        } />

                        {/* Countries route */}
                        <Route path="/countries" element={
                            <ProtectedRoute requiredRoles={['admin']}>
                                <Layout>
                                    <Countries />
                                </Layout>
                            </ProtectedRoute>
                        } />

                        {/* Campaigns routes */}
                        <Route path="/campagnes" element={
                            <ProtectedRoute requiredRoles={['admin']}>
                                <Layout>
                                    <Campaigns />
                                </Layout>
                            </ProtectedRoute>
                        } />
                        <Route path="/campagnes/ajouter" element={
                            <ProtectedRoute requiredRoles={['admin']}>
                                <Layout>
                                    <AddCampaign />
                                </Layout>
                            </ProtectedRoute>
                        } />
                        <Route path="/campagnes/:campaignId/modifier" element={
                            <ProtectedRoute requiredRoles={['admin']}>
                                <Layout>
                                    <EditCampaign />
                                </Layout>
                            </ProtectedRoute>
                        } />
                        <Route path="/campagnes/:campaignId" element={
                            <ProtectedRoute requiredRoles={['admin']}>
                                <Layout>
                                    <Campaign />
                                </Layout>
                            </ProtectedRoute>
                        } />

                        {/* Batches routes */}
                        <Route path="/campagnes/:campaignId/ajouter-batch" element={
                            <ProtectedRoute requiredRoles={['admin']}>
                                <Layout>
                                    <AddBatch />
                                </Layout>
                            </ProtectedRoute>
                        } />
                        <Route path="/campagnes/:campaignId/batch/:batchId/modifier" element={
                            <ProtectedRoute requiredRoles={['admin']}>
                                <Layout>
                                    <EditBatch />
                                </Layout>
                            </ProtectedRoute>
                        } />
                        <Route path="/campagnes/:campaignId/batch/:batchId" element={
                            <ProtectedRoute requiredRoles={['admin']}>
                                <Layout>
                                    <Batch />
                                </Layout>
                            </ProtectedRoute>
                        } />

                        {/* Templates routes */}
                        <Route path="/campagnes/:campaignId/ajouter-template" element={
                            <ProtectedRoute requiredRoles={['admin']}>
                                <Layout>
                                    <AddTemplate />
                                </Layout>
                            </ProtectedRoute>
                        } />
                        <Route path="/campagnes/:campaignId/edit-template/:templateId" element={
                            <ProtectedRoute requiredRoles={['admin']}>
                                <Layout>
                                    <EditTemplate />
                                </Layout>
                            </ProtectedRoute>
                        } />

                        {/* Code Languages routes */}
                        <Route path="/campagnes/:campaignId/declinaison" element={
                            <ProtectedRoute requiredRoles={['admin']}>
                                <Layout>
                                    <AddCodeLang />
                                </Layout>
                            </ProtectedRoute>
                        } />
                        <Route path="/campagnes/:campaignId/code-lang/:codeLangId" element={
                            <ProtectedRoute requiredRoles={['admin']}>
                                <Layout>
                                    <EditCodeLang />
                                </Layout>
                            </ProtectedRoute>
                        } />

                        {/* Eligibles route */}
                        <Route path="/campagnes/:campaignId/batch/:batchId/ajouter-eligibles" element={
                            <ProtectedRoute requiredRoles={['admin']}>
                                <Layout>
                                    <AddEligibles />
                                </Layout>
                            </ProtectedRoute>
                        } />

                        {/* Prices route */}
                        <Route path="/prices" element={
                            <ProtectedRoute requiredRoles={['admin']}>
                                <Layout>
                                    <Prices />
                                </Layout>
                            </ProtectedRoute>
                        } />

                        {/* Logs route */}
                        <Route path="/logs" element={
                            <ProtectedRoute requiredRoles={['admin']}>
                                <Layout>
                                    <Logs />
                                </Layout>
                            </ProtectedRoute>
                        } />

                        {/* No access route */}
                        <Route path="/no-access" element={<NoAccess />} />

                        {/* Catch-all route */}
                        <Route path="*" element={<Navigate to="/" replace />} />
                    </Routes>
                </Router>
            </AuthProvider>
        </NotificationProvider>
    );
};

export default App;
