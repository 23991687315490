import React from 'react';
import ReactPaginate from 'react-paginate';
import './Pagination.css';

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
    return (
        <ReactPaginate
            pageCount={totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={(event) => onPageChange(event.selected + 1)}
            forcePage={currentPage - 1}
            containerClassName={"pagination"}
            activeClassName={"active"}
        />
    );
};

export default Pagination;
