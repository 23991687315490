import React, { useEffect, useState } from 'react';
import BatchForm from '../../../components/organisms/BatchForm/BatchForm';
import config from "../../../config";
import Breadcrumb from "../../../components/molecules/Breadcrumb/Breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import { useNotification } from "../../../NotificationContext";
import { useAuthFetch } from "../../../hooks/useAuthFetch"; // Nouvelle importation

const EditBatch = () => {
    const navigate = useNavigate();
    const [sendDate, setSendDate] = useState('');
    const [name, setName] = useState('');
    const { batchId, campaignId } = useParams();
    const { triggerNotification } = useNotification();
    const authFetch = useAuthFetch(); // Nouvelle constante

    useEffect(() => {
        const fetchBatch = async () => {
            try {
                const response = await authFetch(`${config.API_URL}/emailing/batch/fetch/${batchId}`); // Remplacement de fetch par authFetch
                if (!response.ok) {
                    throw new Error(`Erreur HTTP: ${response.status}`);
                }
                const batch = await response.json();
                setSendDate(batch.scheduled_date);
                setName(batch.name);
            } catch (error) {
                triggerNotification({
                    type: 'error',
                    content: 'Erreur lors de la récupération des détails du batch.',
                    duration: 3000,
                    persistent: false
                });
            }
        };

        fetchBatch();
    }, [batchId, triggerNotification, authFetch]);

    const handleFormSubmit = async (batchData) => {
        try {
            const response = await authFetch(`${config.API_URL}/emailing/batch/update/${batchId}`, { // Remplacement de fetch par authFetch
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(batchData),
            });

            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'Une erreur est survenue lors de la modification du batch');
            }

            const data = await response.json();
            triggerNotification({
                type: 'success',
                content: data.message || 'Le batch a été modifié avec succès.',
                duration: 3000,
                persistent: false
            });
            setTimeout(() => {
                navigate(`/campagnes/${campaignId}/batch/${batchId}`);
            }, 800);
        } catch (error) {
            triggerNotification({
                type: 'error',
                content: (error.message || 'Erreur lors de la communication avec l’API.'),
                duration: 3000,
                persistent: false
            });
        }
    };


    return (
        <div>
            <Breadcrumb />
            <h1>Modifier un batch</h1>
            <BatchForm
                sendDate={sendDate}
                setSendDate={setSendDate}
                name={name}
                setName={setName}
                onSubmit={handleFormSubmit}
            />
        </div>
    );
};

export default EditBatch;
