import React from 'react';
import Sidebar from '../../organisms/Sidebar/Sidebar';
import Header from '../../organisms/Header/Header';
import './Layout.css';
import { useNotification } from "../../../NotificationContext";
import Message from "../../atoms/Message/Message";

const Layout = ({ children, permissions }) => {
    const { notification, clearNotification } = useNotification();

    return (
        <>
            <Header />
            <div className="layout">
                <Sidebar permissions={permissions} />
                <div className="content">
                    {notification && (
                        <Message
                            type={notification.type}
                            onClose={clearNotification}
                            duration={notification.duration}
                            persistent={notification.persistent}
                        >
                            {notification.content}
                        </Message>
                    )}
                    <main className="main">
                        {children}
                    </main>
                </div>
            </div>
        </>
    );
};

export default Layout;
