import React, { useEffect } from 'react';
import './Message.css';

const Message = ({ type, children, onClose, duration = 4000, persistent = false }) => {
    useEffect(() => {
        if (persistent) return;

        const timer = setTimeout(() => {
            if (onClose) {
                onClose();
            }
        }, duration);

        return () => clearTimeout(timer);
    }, [onClose, duration, persistent]);

    return (
        <div className={`message message-${type}`}>
            <span className="message-content">{children}</span>
            {onClose && (
                <button className="message-close" onClick={onClose}>
                    &times;
                </button>
            )}
        </div>
    );
};

export default Message;
