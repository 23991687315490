import React from 'react';
import Button from '../../atoms/Button/Button';
import TextInput from '../../atoms/TextInput/TextInput';
import DatePicker from '../../atoms/DatePicker/Datepicker';
import './CampaignForm.css';

const CampaignForm = ({
                          campaignName,
                          setCampaignName,
                          theoricSendDate,
                          setTheoricSendDate,
                          onSubmit,
                      }) => {
    const handleSubmit = (event) => {
        event.preventDefault();
        onSubmit({
            name: campaignName,
            theoric_scheduled_date: theoricSendDate
        });
    };

    return (
        <div className="campaign-form-container">
            <form className="campaign-form" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="campaignName">Nom de la campagne</label>
                    <TextInput
                        id="campaignName"
                        value={campaignName}
                        onChange={(e) => setCampaignName(e.target.value)}
                        placeholder="Nom de la campagne"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="theoricSendDate">Date théorique d'envoi</label>
                    <DatePicker
                        id="theoricSendDate"
                        value={theoricSendDate}
                        onChange={(e) => setTheoricSendDate(e.target.value)}
                    />
                </div>
                <Button type="submit">Ajouter</Button>
            </form>
        </div>
    );
};

export default CampaignForm;
