import React from 'react';
import './Header.css';
import LogginButton from "../../molecules/LogginButton/LogginButton";
import config from "../../../config";

const Header = () => {
    const operationName = config.OPERATION_NAME;

    return (
        <div className="header">
            <div className="logo">
                <img src="/logo_butterfly_n.png" alt="logo" />
            </div>
            <div className="title">{operationName}</div>
            <LogginButton />
        </div>
    );
};

export default Header;
