import React, { useState, useEffect } from 'react';
import './CodeLangList.css';
import Checkbox from "../../atoms/Checkbox/Checkbox";
import Button from "../../atoms/Button/Button";
import { useNavigate } from "react-router-dom";
import TextInput from "../../atoms/TextInput/TextInput";

const CodeLangList = ({
                          codeLangs,
                          onSelectionChange,
                          campaignId,
                          resetSelection,
                          setResetSelection,
                          savedSelections = [],
                          onSaveSelection,
                          onDeleteSavedSelection
                      }) => {
    const navigate = useNavigate();
    const [selectedCodeLangs, setSelectedCodeLangs] = useState([]);
    const [selectionName, setSelectionName] = useState('');

    const selectAllCodeLangs = () => {
        setSelectedCodeLangs(codeLangs.map(lang => lang.uuid));
    };

    const deselectAllCodeLangs = () => {
        setSelectedCodeLangs([]);
    };

    const toggleCodeLangSelection = (uuid) => {
        setSelectedCodeLangs(prevSelected => {
            if (prevSelected.includes(uuid)) {
                return prevSelected.filter(id => id !== uuid);
            } else {
                return [...prevSelected, uuid];
            }
        });
    };

    useEffect(() => {
        onSelectionChange(selectedCodeLangs);
        if (resetSelection) {
            setSelectedCodeLangs([]);
            setResetSelection();
        }
    }, [selectedCodeLangs, onSelectionChange, resetSelection, setResetSelection]);

    return (
        <div>
            <Button onClick={selectAllCodeLangs}>Tout sélectionner</Button>
            <Button onClick={deselectAllCodeLangs}>Tout déselectionner</Button>
            <TextInput
                value={selectionName}
                onChange={(e) => setSelectionName(e.target.value)}
                placeholder="Nom de la sélection"
            />

            <Button onClick={() => selectionName && onSaveSelection(selectionName)}>Sauvegarder la sélection</Button>
            <div>
                <h3>Sélections Sauvegardées</h3>
                {savedSelections && savedSelections.length > 0 ? (
                    <ul>
                        {savedSelections.map((savedItem, index) => (
                            <li key={index}>
                                <span onClick={() => setSelectedCodeLangs(savedItem.selection)} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                                    {savedItem.name}
                                </span>: ({savedItem.selection.length} items)
                                <Button onClick={() => onDeleteSavedSelection(index)}>Supprimer</Button>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>Aucune sélection sauvegardée</p>
                )}
            </div>

            <ul className="codeLang-list">
                {codeLangs
                    .slice()
                    .sort((a, b) => a.country.localeCompare(b.country))
                    .map((codeLang) => (
                        <li
                            key={codeLang.uuid}
                            className={`codeLang-item ${selectedCodeLangs.includes(codeLang.uuid) ? 'bounce-effect' : ''}`}
                            onClick={() => toggleCodeLangSelection(codeLang.uuid)}
                        >
                            <div className="codeLang-country">
                                {codeLang.country}
                            </div>
                            <div className="codeLang-content">
                                <p>Objet: <strong>{codeLang.email_object}</strong></p>
                                <p>Code Lang: <strong>{codeLang.code_lang}</strong></p>
                                <p className={!codeLang.template_uuid ? 'alert-message template-uuid-info' : 'template-uuid-info'}>
                                    (Template UUID: {codeLang.template_uuid || 'Aucun'})
                                </p>
                            </div>
                            <Checkbox
                                checked={selectedCodeLangs.includes(codeLang.uuid)}
                                onChange={(e) => {
                                    e.stopPropagation();
                                    toggleCodeLangSelection(codeLang.uuid);
                                }}
                            />
                            {codeLang.template_uuid &&
                                <Button onClick={() => navigate(`/campagnes/${campaignId}/code-lang/${codeLang.uuid}`)}>Gérer</Button>
                            }
                        </li>
                    ))}
            </ul>
        </div>
    );
};

export default CodeLangList;
