import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Button from '../../../components/atoms/Button/Button';
import config from "../../../config";
import Breadcrumb from "../../../components/molecules/Breadcrumb/Breadcrumb";
import './Batch.css';
import Status from "../../../components/atoms/Status/Status";
import { convertToDDMMYYYY, convertToDDMMYYYYWithTime } from '../../../utils/dateUtils';
import EligibleList from "../../../components/organisms/EligibleList/EligibleList";
import Pagination from "../../../components/molecules/Pagination/Pagination";
import ToggleButton from "../../../components/atoms/ToggleButton/ToggleButton";
import FilterBar from "../../../components/molecules/FilterBar/FilterBar";
import { useNotification } from "../../../NotificationContext";
import { useAuthFetch } from "../../../hooks/useAuthFetch"; // Nouvelle importation

const Batch = () => {
    const [batch, setBatch] = useState(null);
    const [eligibles, setEligibles] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [totalEligibles, setTotalEligibles] = useState(0);
    const { batchId, campaignId } = useParams();
    const navigate = useNavigate();
    const [emailFilter, setEmailFilter] = useState('');
    const [isSentFilter, setIsSentFilter] = useState('');
    const { triggerNotification } = useNotification();
    const authFetch = useAuthFetch(); // Nouvelle constante

    const fetchEligibles = useCallback(async (page) => {
        try {
            let url = `${config.API_URL}/emailing/eligible/list?batch_uuid=${batchId}&page=${page}`;
            if (emailFilter) {
                url += `&email=${encodeURIComponent(emailFilter)}`;
            }
            if (isSentFilter) {
                url += `&is_sent=${isSentFilter}`;
            }
            const response = await authFetch(url); // Remplacement de fetch par authFetch

            if (!response.ok) {
                throw new Error(`Erreur HTTP: ${response.status}`);
            }
            const data = await response.json();
            setEligibles(data.eligibles);
            setTotalPages(data.totalPages);
            setTotalEligibles(data.totalEligibles);
        } catch (error) {
            console.error("Erreur lors de la récupération des eligibles:", error);
        }
    }, [batchId, emailFilter, isSentFilter, authFetch]);

    const handleEmailFilterChange = (e) => {
        setEmailFilter(e.target.value);
    };

    const handleIsSentFilterChange = (e) => {
        setIsSentFilter(e.target.value);
    };

    const fetchBatchDetails = useCallback(async () => {
        try {
            const response = await authFetch(`${config.API_URL}/emailing/batch/fetch/${batchId}`); // Remplacement de fetch par authFetch
            if (!response.ok) {
                throw new Error(`Erreur HTTP: ${response.status}`);
            }
            const data = await response.json();
            setBatch(data);
        } catch (error) {
            console.error("Erreur lors de la récupération des détails de la campagne:", error);
        }
    }, [batchId, authFetch]);


    useEffect(() => {
        fetchBatchDetails();
        fetchEligibles(currentPage);
    }, [fetchBatchDetails, fetchEligibles, currentPage]);

    const handleDeleteEligibles = async () => {
        try {
            const response = await authFetch(`${config.API_URL}/emailing/eligible/delete`, { // Remplacement de fetch par authFetch
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ batch_uuid: batchId })
            });

            if (!response.ok) {
                throw new Error('Erreur lors de la suppression des éligibles.');
            }

            triggerNotification({
                type: 'success',
                content: 'Tous les éligibles ont été supprimés avec succès.',
                duration: 3000,
                persistent: false
            });

            setEligibles([]);
            setTotalEligibles(0);
            setTotalPages(0);
            setCurrentPage(1);
        } catch (error) {
            triggerNotification({
                type: 'error',
                content: error.message || 'Erreur lors de la communication avec l’API.',
                duration: 3000,
                persistent: false
            });
        }
    };

    const handleEmergencyStop = async () => {
        try {
            const response = await authFetch(`${config.API_URL}/emailing/batch/status/update/${batchId}`, { // Remplacement de fetch par authFetch
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ status: 'error' }),
            });

            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'Une erreur est survenue lors de l’arrêt d’urgence.');
            }
            // Update the batch status locally to reflect the emergency stop
            if (batch && batch.uuid === batchId) {
                setBatch({ ...batch, status: 'error' });
            }
            triggerNotification({
                type: 'success',
                content: 'Arrêt d’urgence effectué avec succès.',
                duration: 3000,
                persistent: false
            });
        } catch (error) {
            console.error("Erreur lors de l’arrêt d’urgence:", error);
            triggerNotification({
                type: 'error',
                content: error.message,
                duration: 3000,
                persistent: false
            });
        }
    };


    const handleToggleBatchStatus = async (batchId, currentStatus) => {
        const newStatus = currentStatus === 'ready' ? 'created' : 'ready';

        try {
            const response = await authFetch(`${config.API_URL}/emailing/batch/status/update/${batchId}`, { // Remplacement de fetch par authFetch
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ status: newStatus }),
            });

            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'Une erreur est survenue lors de la mise à jour du statut du batch');
            }
            if (batch && batch.uuid === batchId) {
                setBatch({ ...batch, status: newStatus });
            }
            triggerNotification({
                type: 'success',
                content: 'Statut du batch mis à jour avec succès.',
                duration: 3000,
                persistent: false
            });

        } catch (error) {
            console.error(`Erreur lors de la mise à jour du statut du batch: ${error}`);
            triggerNotification({
                type: 'error',
                content: `Erreur lors de la mise à jour du statut du batch: ${error}`,
                duration: 3000,
                persistent: false
            });
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleNavigateToEditBatch = () => {
        navigate(`/campagnes/${campaignId}/batch/${batchId}/modifier`);
    };

    const handleNavigateToAddEligibles = () => {
        navigate(`/campagnes/${campaignId}/batch/${batchId}/ajouter-eligibles`);
    }



    return (
        <div>
            <div className="batch-detail">
                <Breadcrumb />

                {batch && (
                    <div>
                        <h1>Batch {batch.name} du {convertToDDMMYYYY(batch.scheduled_date)}</h1>
                        <Button onClick={handleNavigateToEditBatch}>Modifier le batch</Button>
                        <h2>Détails</h2>
                        <p>Date d'envoi: {convertToDDMMYYYYWithTime(batch.scheduled_date)}</p>
                        <p>Date de création: {convertToDDMMYYYYWithTime(batch.create_date)}</p>
                        <Status status={batch.status} />
                        {(batch.status === 'ready' || batch.status === 'created') &&
                            <ToggleButton
                                label={'Prêt pour l\'envoi ?'}
                                value={batch.status === 'ready'}
                                onChange={() => handleToggleBatchStatus(batch.uuid, batch.status)}
                            />
                        }
                        {batch.status === 'active' &&
                            <Button onClick={handleEmergencyStop} className="button-emergency">Arrêt d'urgence</Button>
                        }
                    </div>
                )}
            </div>

            <h2>Éligibles</h2>
            {totalEligibles > 0 ? (
                <Button onClick={handleDeleteEligibles}>Vider les éligibles</Button>
            ) : (
                <Button onClick={handleNavigateToAddEligibles}>Ajouter éligibles</Button>
            )}
            <p>Total éligibles: <b>{totalEligibles}</b> </p>
            <FilterBar
                emailValue={emailFilter}
                isSentValue={isSentFilter}
                onEmailChange={handleEmailFilterChange}
                onIsSentChange={handleIsSentFilterChange}
            />
            <EligibleList eligibles={eligibles} />
            <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={handlePageChange}
            />
        </div>
    );
};

export default Batch;
