import React, { createContext, useContext, useState, useCallback } from 'react';

const NotificationContext = createContext();

export const useNotification = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
    const [notification, setNotification] = useState(null);

    const triggerNotification = useCallback((notification) => {
        setNotification(notification);
        if (notification.duration && !notification.persistent) {
            setTimeout(() => setNotification(null), notification.duration);
        }
    }, []);


    const clearNotification = () => {
        setNotification(null);
    };


    return (
        <NotificationContext.Provider value={{ notification, triggerNotification, clearNotification }}>
            {children}
        </NotificationContext.Provider>
    );
};

